jQuery(document).ready(function($){
	$.noConflict();

	// =============================================
	// HEADER NAVIGATION DROPDOWN
	// =============================================
	// var $nav = $('ul#nav');
	// if ( $nav.length > 0 ) {
	// 	$nav.find('li.parent').addClass('dropdown');
	// 	$nav.find('li.parent>a').addClass('dropdown-toggle');
	// 	// $nav.find('li.parent>a').attr('data-toggle','dropdown'); //remove this line to make parent of dropdown clickable

	// 	$nav.find('li.parent>a').each(function(){
	// 		$(this).html( $(this).html() + '<span class="caret"></span>' );
	// 	});
		
	// 	// $nav.find('li.parent>a').attr('href','#');
	// 	$nav.find('li.parent>ul').addClass('dropdown-menu');

	// }

	// =============================================
	// EDIT TOP LINKS 
	// =============================================
	var $log_in_btn = $('div.top-links ul.links li.last a');

	if ( $('div.top-links ul.links').hasClass('logged-out') ) {
		$log_in_btn.html('Login/My Account&nbsp;<i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>');
	} else {
		$log_in_btn.html('Logout&nbsp;<i class="fa fa-sign-out" aria-hidden="true"></i>');
	}


	var homepage_slider = $("#homepage-slider-carousel .owl-carousel");
	homepage_slider.owlCarousel({

		loop: true,
		singleItem : true,
		autoplay:false,
		nav:false,
		dots:false,
		pagination: false,

		responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	        },
	        600:{
	            items:1,
	        },
	        1000:{
	            items:1
	        }
	    }

	});

	$('#homepage-slider-carousel .owl-controls button#next').click(function() {
		homepage_slider.trigger('next.owl.carousel');
	});
	$('#homepage-slider-carousel .owl-controls button#prev').click(function() {
		homepage_slider.trigger('prev.owl.carousel');
	});


	// =============================================
	// CATEGORY DESCRIPTION  SHOW ALL TOGGLE BUTTON
	// =============================================
	$( ".read-more a.toggle" ).click(function() {

	    $('.read-more a.toggle').toggleClass('shown');
	    $('.category-description').toggleClass('shown');

	    if( $('.read-more a.toggle').hasClass('shown')) {
	    	$('.read-more a.toggle').html('Read Less...');
	    	
	    	$('.read-more').addClass('shown');

	    } else {
	    	$('.read-more a.toggle').text('Read More...');
	    	
	    	$('.read-more').removeClass('shown');
	    }

	    return false;
	});


	// HIDES READ MORE IF CATEGORY HAS SHORT DESCRIPTION
	if ( $(".category-description.std").text().trim().length < 160 ) {
		$(".read-more").hide();
	}	

	$("ul.main-categories>li:not(:has(>a.chevron-fa))").addClass("no-sub");



	// SINGLE PRODUCT IMAGE GALLERY CAROUSEL
	jQuery('#product-gallery-slider .owl-carousel').owlCarousel({
            loop: true,
            singleItem : true,
            autoplay:false,
            nav:true,
            dots:false,
            center: true,
            items: 1,
            pagination: false,
            responsiveClass:true,
            navText: [
            "<i class='icon-chevron-left icon-white'><</i>",
            "<i class='icon-chevron-right icon-white'>></i>"
            ],

    });

    // SINGLE PRODUCT IMAGE LIGHTBOX
    lightbox.option({
      'fadeDuration': 200,
      'alwaysShowNavOnTouchDevices': true,
      'disableScrolling': true,
      'positionFromTop': 100,
      'wrapAround': true
    });


    // =============================================
	// Category Sidebar Toggle Event
	// =============================================
	if ( $('.category-dropdown-nav-btn').length > 0 ) {
		$('.category-dropdown-nav-btn').click(function(evt) {
			evt.preventDefault();

			// $(this).next('.dropdown-menu-2').slideToggle();
			$('.left-category-nav .block-content').slideToggle();
			$(this).find('i.fa').toggleClass('fa-caret-down fa-caret-right ');
		});
	}

	if ( $('.subcategory-dropdown-nav-btn').length > 0 ) {
		$('.subcategory-dropdown-nav-btn').click(function(evt) {
			evt.preventDefault();

			$(this).next('.subcategory-dropdown').slideToggle();
			$(this).find('i.fa').toggleClass('fa-caret-right fa-caret-down');
		});
	}

	if ( $ ('.left-category-nav').length > 0 ) {
		var $left_nav = $ ('.left-category-nav');
	}


	// =============================================
	// Filter By Toggle Event
	// =============================================
	if ( $('.filter-by-dropdown-nav-btn').length > 0 ) {
		$('.filter-by-dropdown-nav-btn').click(function(evt) {
			evt.preventDefault();

			// $(this).next('.dropdown-menu-2').slideToggle();
			$('.left-nav-filter-by .block-content').slideToggle();
			$(this).find('i.fa').toggleClass('fa-caret-down fa-caret-right');
		});
	}

	if ( $('.filter-by-2nd-level-dropdown-nav-btn').length > 0 ) {
		$('.filter-by-2nd-level-dropdown-nav-btn').click(function(evt) {
			evt.preventDefault();

			$(this).parent().next().find('.dropdown').slideToggle();

			$(this).find('i.fa').toggleClass('fa-caret-down fa-caret-right');
		});
	}

	$('.ellip-three-lines').ellipsis({
	  lines: 3,             // force ellipsis after a certain number of lines. Default is 'auto'
	  ellipClass: 'ellip',  // class used for ellipsis wrapper and to namespace ellip line
	  responsive: false      // set to true if you want ellipsis to update on window resize. Default is false
	});

	$('.ellip-four-lines').ellipsis({
	  lines: 4,             // force ellipsis after a certain number of lines. Default is 'auto'
	  ellipClass: 'ellip',  // class used for ellipsis wrapper and to namespace ellip line
	  responsive: false      // set to true if you want ellipsis to update on window resize. Default is false
	});

});